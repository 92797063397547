<template>
    <div class="acDiv">
        <div>酷鹏凯迪科技（北京）有限公司</div>
<p>公司成立于 2016 年，是一家专注于软件产品销售及研发的高科
技公司。我们面向企业级客户提供全面的软件产品及软件资产服务，提供领先于市场的
数字化转型解决方案。</p>
<p>公司在上海、广州、无锡、成都立分公司，形成了以北京为总部
中心，覆盖全国的销售、服务网络，拥有从软件研发、软件销售到软件服务完整的体系。</p>
<p>我们的愿景是专注于软件行业，成为业内一流的数字化转型服务提供商。我们的价值观
是以“创新、守信、共赢、互惠”作为经营理念和品牌承诺。</p>


      <div style="padding:100px 0">
          <img src="../../static/company1.jpg" style="width:100%"/>
      </div>
    </div>
</template>
<script>
export default {
    name:"aboutCompany"
}
</script>
<style>
.acDiv div{
    font-size:20px;
    color:#212121;
    padding:50px 0;
    line-height:20px;
    text-align:center;
    margin-top:20px;
}
.acDiv p{
    color:#404756;
    line-height:32px;
    text-indent: 2em;
}
.acDiv p span{
    text-indent: 2em;
}
</style>
